<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >  <template #overlay>
         <b-card

           class="text-center"
         >

           <h3>
             <b-spinner

               class="mr-1"
               label="Loading"
             />
             Invoices Loading...
           </h3>

         </b-card>
       </template>

      <b-table-simple
        v-if="invoicesData"

        small
        caption-top
        responsive
      >

        <b-thead>

          <b-tr>
            <b-th>{{ $t('AMOUNT') }}</b-th>
            <b-th>{{ $t('INVOICE NUMBER') }}</b-th>
            <b-th>{{ $t('CREATED') }}</b-th>
            <b-th>{{ $t('PAYMENT PERIOD') }}</b-th>
            <b-th />
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(data, index) in invoicesData"
            :key="index"
          >
            <b-td>${{ data.total_amount }} {{ data.currency }} <b-badge
              v-if="data.paid"
              variant="success"
            >
              paid
            </b-badge><b-badge
              v-else
              variant="danger"
            >
              non paid
            </b-badge></b-td>
            <b-td>{{ data.number }}</b-td>
            <b-td>{{ $format_datetime(data.created_date ) }}</b-td>
            <b-td>{{ $format_datetime(data.period_start ) }} - {{ $format_datetime(data.period_end ) }}</b-td>
            <b-td>
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item

                  :href="data.invoice_url"
                  target="_blank"
                >
                  <feather-icon icon="EyeIcon" />
                  <span class="align-middle ml-50">{{ $t('View Invoice') }} </span>
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  :href="data.invoice_pdf"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">{{ $t('Invoice Pdf') }} </span>
                </b-dropdown-item>

              </b-dropdown></b-td>
          </b-tr>

        </b-tbody>
      </b-table-simple>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store'
import {
  BOverlay, BSpinner, BCard, BTableSimple, BThead, BTbody, BTr, BTd, BTh, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BTableSimple,
    BThead,
    BBadge,
    BTbody,
    BTr,
    BTd,
    BTh,
    BCard,
  },
  data() {
    return {
      invoicesData: null,
      pageIsLoading: true,

    }
  },
  created() {
    this.getInvoicesData()
  },
  methods: {
    getInvoicesData() {
      store
        .dispatch('app-account/fetchInvoicesData')
        .then(response => {
          this.invoicesData = response.data.data
          this.pageIsLoading = false
        })
        .catch(() => {
          this.pageIsLoading = false
        })
    },
  },
}
</script>
