<template>
  <div>
    <b-modal
      id="taxFormmodal"
      ref="taxFormmodal"
      title="Edit withholding tax"
      ok-only
      ok-title="Save tax"
      size="lg"
      @ok="saveData"
    >
      <validation-observer ref="taxForm">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Tax label"
                label-for="taxName"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tax label"
                  rules="required"
                >
                  <b-form-input
                    id="taxName"
                    v-model="localTaxData.taxName"
                    size="lg"
                    placeholder="Enter a label"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group

                label="Tax rate"
                label-for="taxRate"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tax rate"
                  rules="required"
                >
                  <cleave
                    id="taxRate"
                    v-model="localTaxData.taxRate"
                    class="form-control"
                    :raw="true"
                    :options="$staticParams.taxClaveOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/named
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import accountStoreModule from '../../accountStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    Cleave,
  },
  props: {
    taxName: {
      type: String,
      required: true,
    },
    taxRate: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localTaxData: null,
      // validation rules
      required,
    }
  },
  watch: {
    taxName() { this.resetLocalData() },
    taxRate() { this.resetLocalData() },
  },
  created() {
    this.resetLocalData()
  },
  methods: {
    showEditor() {
      this.$refs.taxFormmodal.show()
    },
    resetLocalData() {
      this.localTaxData = { taxName: this.taxName, taxRate: this.taxRate }
    },
    saveData(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.taxForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$emit('dataChanged',this.localTaxData)

          this.$nextTick(() => {
            this.$refs.taxFormmodal.hide()
          })
        }
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-account'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return { }
  },
}
</script>
