<template>
  <div v-if="localOptions">
    <withholding-tax-editor
      ref="withholdingTaxEditorComponent"
      :tax-name="localOptions.withholding_tax_label"
      :tax-rate="parseFloat(localOptions.withholding_tax_rate)"
      @dataChanged="withholdingTaxDataChanged"
    />
    <tax-editor
      v-show="selectedTaxObject"
      ref="taxEditorComponent"
      :tax-object="selectedTaxObject"
      :all-tax-list="localOptions.tax_data"
      @dataChanged="taxDataChanged"
    />
    <b-card>
      <!-- form -->
      <b-form>
        <status-alert
          :is-success="isSuccess"
          :error-data="errorData"
          :success-msg="$t('Tax and currency info updated')"
        />
        <b-row>
          <!-- Business Name -->
          <b-col cols="12">
            <h2>Registration and certification</h2>
            <b-form-group
              v-if="true==false"
              label="TKN"
              label-for="tkn"
            >
              <b-form-input
                id="tkn"
                v-model="localOptions.tkn"
                size="lg"
                placeholder="Enter your registration/certification..."
              />
            </b-form-group>
            <h2 class="mt-3 mb-2">
              Location
            </h2>
            <b-form-group
              v-if="countryOptions"
              :label="$t('Country')"
              label-for="country_KDR"
            >
              <v-select
                v-model="localOptions.country_id"
                class="lg-input"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="val => val.value"
                autocomplete="chrome-off"
                :clearable="false"
                input-id="country_KDR"
              />
            </b-form-group>
            <b-form-group
              v-if="countryOptions"
              :label="$t('Currency')"
              label-for="currency_select"
            >
              <v-select

                v-model="localOptions.currency_id"
                class="lg-input"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="currencyOptions"
                :reduce="val => val.value"
                autocomplete="chrome-off"
                :clearable="false"
                input-id="currency_select"
              />
            </b-form-group>
            <h2 class="mt-3 mb-2">
              Tax year starts
            </h2>
            <b-row>
              <b-col md="6">
                <b-form-group
                  v-if="countryOptions"
                  :label="$t('Month')"
                  label-for="tax_start_month"
                >

                  <v-select

                    v-model="localOptions.tax_start_month"
                    class="lg-input"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="$staticParams.monthOptions"
                    :reduce="val => val.value"
                    autocomplete="chrome-off"
                    :clearable="false"
                    input-id="tax_start_month"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-if="countryOptions"
                  :label="$t('Day')"
                  label-for="tax_start_day"
                >

                  <v-select

                    v-model="localOptions.tax_start_day"
                    class="lg-input"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="$staticParams.dayOptions()"
                    :reduce="val => val"
                    autocomplete="chrome-off"
                    :clearable="false"
                    input-id="tax_start_day"
                  />

                </b-form-group>
              </b-col>

            </b-row>
            <h2 class="mt-3 mb-2">
              Rates
            </h2>
            <span class="mb-2">A tax can have multiple rates associated with it. Edit a tax to add additional rates. Default taxes and rates will be applied to items.</span>
            <hr>
            <b-form-group

              v-for="(data,index) in localOptions.tax_data"
              :key="index"
              :label="data.tax_name"
              label-for="withholding_tax_rate"
            >
              <div class="d-flex justify-content-between align-items-center">
                <b-form-input
                  :value="data.tax_rates.filter(filter=>filter.is_default==1)[0].rate+'% '+ (data.tax_rates.length>1?'('+data.tax_rates.length+' more rates)':'')"
                  :readonly="true"
                  size="lg"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"
                  class="ml-2"
                  @click="editTax(data)"
                >
                  Edit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-danger"
                  class="ml-2"
                  @click="deleteTax(data)"
                >
                  Delete
                </b-button>
              </div>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              class="ml-2"
              @click="addNewTax"
            >
              Add tax
            </b-button>
            <hr>
            <h2
              v-if="true==false"
              class="mb-3 mb-2"
            >
              Withholding
            </h2>
            <b-form-checkbox
              v-if="true==false"
              v-model="localOptions.withholding_tax_enable"
              checked="true"
              name="check-button"
              switch
              inline
              :value="1"
              :unchecked-value="0"
            >
              Enable withholding tax
            </b-form-checkbox>

            <b-form-group
              v-if="localOptions.withholding_tax_enable==1 && true==false"
              :label="localOptions.withholding_tax_label"
              label-for="withholding_tax_rate"
            >
              <div class="d-flex justify-content-between align-items-center">
                <cleave
                  id="withholding_tax_rate"
                  v-model="localOptions.withholding_tax_rate"
                  class="form-control"
                  :raw="true"
                  :options="$staticParams.taxClaveOptions"
                  :readonly="true"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"
                  class="ml-2"
                  @click="$refs.withholdingTaxEditorComponent.showEditor()"
                >
                  Edit
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <!--/ Business Name -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="saveData"
            >
              Save changes
            </b-button>
            <b-button
              v-if="true==false"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
import withholdingTaxEditor from './components/WithholdingTaxEditor.vue'
import taxEditor from './components/TaxEditor.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    withholdingTaxEditor,
    taxEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSuccess: false,
      errorData: null,
      countryOptions: [],
      currencyOptions: [],
      selectedTaxObject: null,
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  created() {
    this.fetchCurrencies()
    this.fetchCountries()
  },
  methods: {
    addNewTax() {
      this.selectedTaxObject = null
      setTimeout(() => this.$refs.taxEditorComponent.showEditor(), 300)
    },
    editTax(data) {
      this.selectedTaxObject = data
      setTimeout(() => this.$refs.taxEditorComponent.showEditor(), 300)
    },
    deleteTax(data) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.localOptions.tax_data.length; i++) {
        if (this.localOptions.tax_data[i].id === data.id) {
          this.localOptions.tax_data.splice(i, 1)
        } else {
          this.localOptions.tax_data[i].accumulative.forEach((val, index) => {
            if (val === data.id) {
              this.localOptions.tax_data[i].accumulative.splice(index, 1)
            }
          })
        }
      }
    },
    taxDataChanged(taxData) {
      let currentIndex = -1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.localOptions.tax_data.length; i++) {
        if (this.localOptions.tax_data[i].id === taxData.id) {
          currentIndex = i
        }
      }

      if (currentIndex >= 0) {
        this.$set(this.localOptions.tax_data, currentIndex, taxData)

        this.localOptions.tax_data[currentIndex] = taxData
        // console.log(this.localOptions.tax_data[currentIndex])
      } else {
        this.localOptions.tax_data.push(taxData)
      }
      this.saveData()
    },
    withholdingTaxDataChanged(taxData) {
      this.localOptions.withholding_tax_rate = taxData.taxRate
      this.localOptions.withholding_tax_label = taxData.taxName
    },
    saveData() {
      store
        .dispatch('app-account/updateTaxAndCurrencyInfo', this.localOptions)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
        //  this.userData = response.data.data

          this.isSuccess = true
          this.errorData = null
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    fetchCountries() {
      store.dispatch('app/fetchCountries', {})
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ label: val.name, value: val.id }))

          this.countryOptions = arr
        })
    },
    fetchCurrencies() {
      store.dispatch('app/fetchCurrencies', {})
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ label: `${val.currency_code} - ${val.currency_name}`, value: val.id }))
          this.currencyOptions = arr
        })
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.lg-input{
      height: 3.2857rem;
    font-size: 1.143rem;
    line-height: 1.25;
}

</style>
