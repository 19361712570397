<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <b-card

          class="text-center"
        >

          <h2>
            <b-spinner

              class="mr-1"
              label="Loading"
            />
            {{ overlayMsg }}
          </h2>

        </b-card>
      </template>
      <b-alert
        v-if="$user().email_verified_at==null"
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>Please verify your e-mail address. If you did not receive a verification link to your e-mail address, you can resend it by <strong><b-link @click="resendEmailVerificationLink">clicking  here.</b-link></strong></span>
        </div>
      </b-alert>
      <b-tabs
        v-if="userData"
        v-model="tabIndex"
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >

        <!-- general tab -->
        <b-tab v-if="true==false">

          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">General</span>
          </template>

          <account-setting-general
            v-if="true==false"
            :general-data="userData"
          />
        </b-tab>
        <!--/ general tab -->

        <!-- change password tab -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Change Password</span>
          </template>

          <account-setting-password />
        </b-tab>
        <!--/ change password tab -->

        <!-- info -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Company Info</span>
          </template>

          <account-setting-company-info
            v-if="userData.company_info"
            :information-data="userData.company_info"
          />
        </b-tab>

        <!-- social links -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="BellIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Notifications</span>
          </template>

          <account-setting-notification
            v-if="userData.notifications"
            :notification-data="userData.notifications"
          />
        </b-tab>

        <!-- tax and currency -->
        <b-tab href="#tax-and-currency">

          <!-- title -->
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Tax and Currency</span>
          </template>

          <account-setting-tax-and-currency
            v-if="userData.tax_and_currency_info"
            :information-data="userData.tax_and_currency_info"
          />
        </b-tab>
        <!-- tax and currency -->
        <!-- notification -->
        <b-tab @click="goToInvoiceDesigner">

          <!-- title -->
          <template #title>
            <feather-icon
              icon="TrelloIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Invioce Design</span>
          </template>

        </b-tab>
        <!-- notification -->
        <!-- notification -->
        <b-tab href="#manage-subscription">

          <!-- title -->
          <template #title>
            <feather-icon
              icon="CreditCardIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Manage Subscription</span>
          </template>

          <account-setting-subscription @pageLoading="handlePageLoading" />
        </b-tab>
      <!-- notification -->

      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTabs, BTab, BAlert, BLink, BCard, BSpinner, BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingCompanyInfo from './AccountSettingCompanyInfo.vue'
import AccountSettingTaxAndCurrency from './AccountSettingTaxAndCurrency.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import AccountSettingSubscription from './AccountSettingSubscription.vue'
import accountStoreModule from '../accountStoreModule'

export default {
  components: {
    BTabs,
    BLink,
    BTab,
    BCard,
    BSpinner,
    BOverlay,
    BAlert,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingCompanyInfo,
    AccountSettingNotification,
    AccountSettingSubscription,
    AccountSettingTaxAndCurrency,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      pageIsLoading: false,
      overlayMsg: 'Loading...',

      userData: null,
      tabIndex: 1,
      tabs: ['#change-password', '#company-info', '', '#tax-and-currency', '#invoice-design', '#manage-subscription'],
    }
  },
  watch: {
     tabIndex(val) {
      if (this.tabs[val]) {
        if (this.tabs[val] === '#tax-and-currency') {
          this.$userHandler.setUsageStep('check_tax_details')
        }
      }
    },
  },
  created() {
    this.fetchData()

    if (this.$route.hash) {
      this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash)
    } else {
      // this.tabIndex = 0
    }
  },
  methods: {
    goToInvoiceDesigner() {
      this.$destroy()
      this.$router.replace({ name: 'settings-invoice-design' })
    },
    handlePageLoading(isLoading) {
      if (isLoading) {
        this.pageIsLoading = true
      } else {
        this.pageIsLoading = false
      }
    },
    resendEmailVerificationLink() {
      this.pageIsLoading = true
      this.overlayMsg = 'Verificatin link sending...'
      store
        .dispatch('app-account/resendEmailVerificationLink')
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'A new verification link has been sent to your e-mail address. Please check your inbox.',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.pageIsLoading = false
          if (error.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fail',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    fetchData() {
      store
        .dispatch('app-account/fetchAccountData')
        .then(response => {
          this.userData = response.data.data
        })
        .catch(() => {
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-account'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return { }
  },
}
</script>
