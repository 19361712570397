<template>
  <b-row>
    <b-col xl="6">
      <b-card
        v-if="subscriptionData"
        no-body
        class="border-primary"
      >

        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
          <h2 class="mb-0">
            Current Plan Details
          </h2>
          <b-badge variant="light-primary">
            {{ subscriptionData.plan_name }}
          </b-badge>

        </b-card-header>

        <b-card-body>
          <status-alert
            :is-success="isSuccess"
            :error-data="errorData"
            :success-msg="successMsg"
          />

          <table class="mt-2 mt-xl-0 w-50">
            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">Plan Name</span>
              </th>
              <td class="pb-50">
                {{ subscriptionData.plan_name }}
              </td>
            </tr>
            <tr v-if="!subscriptionData.without_payment">
              <th class="pb-50">

                <span class="font-weight-bold">Payment Period</span>
              </th>
              <td class="pb-50">
                <span v-if="subscriptionData.payment_period=='monthly'">Monthly</span>
                <span v-if="subscriptionData.payment_period=='yearly'">Yearly</span>
              </td>
            </tr>
            <tr v-if="subscriptionData.start_at">
              <th class="pb-50">

                <span class="font-weight-bold">Start Date</span>
              </th>
              <td class="pb-50">
                {{ $format_datetime(subscriptionData.start_at) }}
              </td>
            </tr>
            <tr v-if="subscriptionData.ends_at">
              <th class="pb-50">

                <span class="font-weight-bold">End Date</span>
              </th>
              <td class="pb-50">
                {{ $format_datetime(subscriptionData.ends_at) }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">Monthly Invoices Limit</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span v-if="subscriptionData.invoice_limit && subscriptionData.invoice_limit>0"> {{ subscriptionData.invoice_limit }}</span>
                <span v-if="subscriptionData.invoice_limit==null"> Unlimited</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">Invoices remaining</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span v-if="subscriptionData.remaining_invoice_limit==null"> Unlimited</span>
                <span v-else> {{ subscriptionData.remaining_invoice_limit }}</span>

              </td>
            </tr>
            <tr>
              <th class="pb-50" />
              <td class="pb-50 text-capitalize" />
            </tr>
          </table>
          <hr>
          <b-button
            v-if="subscriptionData.without_payment"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1"
            variant="warning"
            block
            @click="goToSubscriptionPage"
          >
            Upgrade Plan
          </b-button>
          <b-button
            v-if="!subscriptionData.without_payment"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1"
            variant="warning"
            block
            @click="goToBillingPortal"
          >
            Change Plan or Show Invoice & Payment Details
          </b-button>
          <hr>
          <card-manager
            v-if="true==false"
            class="mt-1 mb-2"
            :card-element-show-type="cardElementShowType"
          />
          <invoices v-if="true==false" />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import cardManager from '../subscription/CardManager.vue'
import invoices from '../subscription/Invoices.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
BCol,
    cardManager,
    invoices,
  },
  data() {
    return {
      cardElementShowType: 'none',
      errorData: null,
      isSuccess: false,
      successMsg: '',
      subscriptionData: null,
    }
  },
  created() {
    if (this.$route.query.subscription_status === 'true') {
this.$store.state.paymentCompleted = true
    }
    if (this.$store.state.paymentCompleted) {
      this.successMsg = 'Your subscription is started'
      this.isSuccess = true
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.$store.state.paymentCompleted = false, 5000)
    }
    this.getSubscription()
  },
  mounted() {

  },

  methods: {
    goToSubscriptionPage() {
      this.$destroy()
     this.$router.replace({ path: '/subscription' })
    },
    goToBillingPortal() {
      this.$emit('pageLoading', true)
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/subscription-billing-portal?token=${useJwt.getToken()}`

      window.location.href = `${excelUrl}`
      this.$emit('pageLoading', false)
    },
    cancelSubscription() {
      this.$swal({
        title: this.$t('Are you sure you want to unsubscribe?'),
        text: this.$t('Your current fee renewal will be stopped when you unsubscribe. You will be able to continue using your current subscription until the end of the subscription period, but it will not be renewed when the subscription expires.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Cancel Subscription'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-account/cancelSubscription')
            .then(() => {
              this.successMsg = 'Your subscription is canceled'
              this.isSuccess = true
              this.getSubscription()
            })
            .catch(error => {
              this.successMsg = ''
              this.isSuccess = false
              this.pageIsLoading = false
              this.errorData = error.response.data
            })
        }
      })
    },
    getSubscription() {
      this.pageIsLoading = true
      store
        .dispatch('app-account/fetchSubscription')
        .then(response => {
          this.subscriptionData = response.data.data
          this.pageIsLoading = false
          if (!this.subscriptionData.without_payment && this.subscriptionData.active) {
            this.cardElementShowType = 'button-triggerd'
          }
           const userData = this.$user()
              userData.subscription = this.subscriptionData
              localStorage.setItem('userData', JSON.stringify(userData))
              store.commit('app/UPDATE_USER_DATA', userData)
        })
        .catch(() => {
          this.pageIsLoading = false
        })
    },
  },
}
</script>

<style>

</style>
