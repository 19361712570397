<template>
  <div>

    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >  <template #overlay>
         <b-card

           class="text-center"
         >

           <h2>
             <b-spinner

               class="mr-1"
               label="Loading"
             />
             Loading...
           </h2>

         </b-card>
       </template>
      <status-alert
        :is-success="isSuccess"
        :error-data="errorData"
        :success-msg="successMsg"
      />

      <div
        v-if="cardData==null && pageIsLoading==false && cardElementShowType=='button-triggerd'"
        class=" mb-1 mb-md-2 "
      >
        <b-button
          variant="primary"
          size="sm"
          @click="handleAddNewCardButton"
        >
          <feather-icon
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t('Add New Credit Card') }}</span>
        </b-button>
      </div>
      <card-element
        v-if="cardData==null && pageIsLoading==false && cardElementShow==true"
        ref="cardElement"
        @elementReady="readyCardElement"
        @tokenCreated="tokenCreated"
      />
      <div
        v-if="cardData==null && pageIsLoading==false && cardElementShow==true && cardElementShowType=='button-triggerd'"
        class=" mb-1 mb-md-2 mt-1 "
      >
        <b-button
          variant="primary"
          size="sm"
          @click="getCardToken"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t('Save Card') }}</span>
        </b-button>
      </div>
      <b-list-group
        v-if="cardData"
        flush
      >

        <h2>Current Card</h2>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CreditCardIcon"
              size="24"
              class="mr-50"
            />
            <h3>{{ cardData.brand }}  - xxxx xxxx xxxx {{ cardData.last4 }} {{ cardData.exp_month }}/{{ cardData.exp_year }}</h3>
          </div>
          <b-link
            style="color:#ea5455"
            variant="danger"
            @click="deleteCard"
          >
            Delete card
          </b-link>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store'
import {
  BOverlay, BSpinner, BListGroup, BListGroupItem, BCard, BButton, BLink,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import cardElement from './CardElement.vue'

export default {
  components: {
    BSpinner,
    BOverlay,
    BLink,
    BListGroup,
    BListGroupItem,
    BCard,
    BButton,
    cardElement,
  },
  props: {
    cardElementShowType: {
      type: String,
      default: 'direct',
    },
  },
  data() {
    return {
      cardElementShow: false,
      cardData: null,
      pageIsLoading: true,
      token: null,
      errorData: null,
      isSuccess: false,
      successMsg: '',

    }
  },
  watch: {
    cardElementShowType() {
      this.handleCardElementShow()
    },
  },
  created() {
    this.getCardData()
    this.handleCardElementShow()
  },
  methods: {
    handleCardElementShow() {
      if (this.cardElementShowType === 'direct') {
        this.cardElementShow = true
      }
    },
    tokenCreated(token) {
      this.token = token//
      this.addNewCard()

      // handle the token
      // send it to your server
    },
    handleAddNewCardButton() {
      this.cardElementShow = true
    },
    getCardToken() {
      this.$refs.cardElement.submit()
    },
    deleteCard() {
      this.$swal({
        title: this.$t('Are you sure ?'),
        text: this.$t('When you delete your card, your subscription will not be renewed unless you add a new card.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete now'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.pageIsLoading = true
          store.dispatch('app-account/deleteCard')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Card is deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.getCardData()
            })
            .catch(error => {
              this.pageIsLoading = false
              this.isSuccess = false

              this.errorData = error.response.data
            })
        }
      })
    },
    addNewCard() {
      this.pageIsLoading = true
      store
        .dispatch('app-account/addNewCard', { token: this.token.id })
        .then(response => {
          this.successMsg = 'New card added'
          this.errorData = null
          this.isSuccess = true
          this.cardData = response.data.data
          this.pageIsLoading = false
          this.cardData.isNewCard = true
          this.$emit('cardAdded', this.cardData)
        })
        .catch(error => {
          this.$emit('cardAddError', this.cardData)
          this.pageIsLoading = false
          this.isSuccess = false

          this.errorData = error.response.data
        })
    },
    readyCardElement() {
      this.pageIsLoading = false
    },
    getCardData() {
      store
        .dispatch('app-account/fetchCardData')
        .then(response => {
          this.cardData = response.data.data.card_info
          this.pageIsLoading = false
          this.$emit('dataFetched', this.cardData)
        })
        .catch(() => {
          this.pageIsLoading = false
        })
    },
  },
}
</script>
