<template>
  <div>
    <stripe-element-card
      ref="elementRef"
      :pk="publishableKey"
      :element-options="elementOptions"
      :hide-postal-code="true"
      @token="tokenCreated"
      @element-ready="elementReady"
    />

  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe'

export default {
  components: {
    StripeElementCard,

  },
  data() {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      token: null,
      elementOptions: {
        appearance: {
          theme: 'stripe',
        },
      },

    }
  },
  created() {

  },
  methods: {
    elementReady() {
      this.$emit('elementReady')
    },
    submit() {
      // this will trigger the process
      this.$refs.elementRef.submit()
    },
    tokenCreated(token) {
      this.$emit('tokenCreated', token)
      // handle the token
      // send it to your server
    },
  },
}
</script>
