<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        NOTIFICATIONS
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="enable-notifications"
          v-model="localOptions.is_enable_email_notifications"
          class="mb-1"
          :value="true"
          :unchecked-value="false"
          name="enable-notifications"
          switch
          inline
        >
          <span>Subscribe to emails</span>

        </b-form-checkbox>
        <hr>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="product-updates"
          v-model="localOptions.is_enable_product_updates_notifications"
          name="product-updates"
          :value="true"
          :unchecked-value="false"
          switch
          inline
          :disabled="!localOptions.is_enable_email_notifications"
        >
          <span>New features and improvements</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="marketing-notifications"
          v-model="localOptions.is_enable_marketing_notifications"
          name="marketing-notifications"
          :value="true"
          :unchecked-value="false"
          :disabled="!localOptions.is_enable_email_notifications"
          switch
          inline
        >
          <span>Special offers, contests, giveaways and prizes</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="support-notifications"
          v-model="localOptions.is_enable_support_notifications"
          name="support-notifications"
          :value="true"
          :disabled="!localOptions.is_enable_email_notifications"
          :unchecked-value="false"
          switch
          inline
        >
          <span>Tips and how-to guides</span>
        </b-form-checkbox>
      </b-col>
      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="saveData"
        >
          Save changes
        </b-button>

      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
    }
  },
  methods: {
    saveData() {
      store
        .dispatch('app-account/updateNotificationSettings', this.localOptions)
        .then(response => {
          this.userData = response.data.data
          this.isSuccess = true
          this.errorData = null
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
  },
}
</script>
